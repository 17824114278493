import Vue from 'vue'
import Vuex from 'vuex'
import { auth } from './auth.module'
import ApiService from '../services/api.service'
import VueI18n from '@/i18n'
import { getI18nDateTimeFormat } from '@/lib/i18nDateTimeFormat'
import UserService from '@/services/user.service'
import { main } from '@/store/main'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    auth,
    main
  },
  state: {
    id: null,
    finishedGames: true,
    loading: true,
    isNavOpened: false,
    isMiniNavOpened: false,
    config: null,
    settings: null,
    ytThumbnails: null,
    user: {
      name: '',
      roles: [],
      clients: []
    },
    currentSeason: {},
    domains: {},
    seasonOptions: [],
    availableLanguages: [],
    language: '',
    currentClient: null
  },
  mutations: {
    loading (state) {
      state.loading = true
    },
    loaded (state) {
      state.loading = false
    },
    setCurrentSeason (state, season) {
      state.currentSeason = season
    },
    async currentUser (state, user) {
      state.user = user
      state.currentSeason = user.currentSeason

      let client = null

      if (user.currentClientId) {
        const clientId = user.currentClientId

        client = state.user.clients.find(client => client.uuid === clientId)

        if (!client) {
          UserService.logout()
        }
      } else {
        client = state.user.clients.find(client => client.uuid === localStorage.getItem('le-client')) ?? state.user.clients[0]

        if (!client) {
          UserService.logout()
        }
      }

      return await this.dispatch('setClient', client)
    },
    updateClient (state, data) {
      this.dispatch('fetchGames')

      state.id = data.id
      state.currentSeason = data.currentSeason
      state.config = data.config
      state.settings = data.settings
      state.domains = data.domains

      console.log(data)

      VueI18n.setDateTimeFormat('en', getI18nDateTimeFormat(state.settings.formatOfTime === '12h-clock'))
      VueI18n.setDateTimeFormat('sr', getI18nDateTimeFormat(state.settings.formatOfTime === '12h-clock'))
      VueI18n.setDateTimeFormat('sl', getI18nDateTimeFormat(state.settings.formatOfTime === '12h-clock'))
    },
    setSeasonOptions (state, data) {
      state.seasonOptions = data
    }
  },
  getters: {
    loading: state => state.loading,
    isAdmin (state) {
      return state.currentClient.role === 'ADMIN' || state.user.isSuperAdmin
    },
    isManager (state) {
      return state.currentClient.role === 'MANAGER' || state.user.isSuperAdmin
    }
  },
  actions: {
    async setClient ({ state }, client) {
      state.currentClient = client
      localStorage.setItem('le-client', client.uuid)
      ApiService.setClient(client.uuid)
      await this.dispatch('getClient')

      return client
    },
    getClient ({ commit }) {
      ApiService.get('api/scorekeeper/config').then(response => {
        commit('updateClient', response.data)
      })
    },
    getAvailableLanguages ({ state }) {
      state.availableLanguages = require('../../../lm-locales/config/languages.json')
    },
    initLanguage (store) {
      let lang

      if (localStorage.getItem('locale')) {
        lang = localStorage.getItem('locale')
      } else {
        lang = 'en'
      }

      store.dispatch('setLanguage', lang)
    },
    setLanguage ({ state }, lang) {
      state.language = lang
      VueI18n.locale = lang
      localStorage.setItem('locale', lang)
    },
    fetchGames ({ state }) {
      // DashboardService.games().then(res => {
      //   state.finishedGames = res.data
      // })
    },
    toggleMiniNav ({ state }) {
      state.isMiniNavOpened = !state.isMiniNavOpened
    },
    toggleNav ({ state }) {
      state.isNavOpened = !state.isNavOpened
    },
    closeNav ({ state }) {
      state.isNavOpened = false
    },
    getSeasonOptions ({ commit }, q = '') {
      const search = q || ''
      ApiService.get(`api/v1/admin/search/seasons?search=${search}`).then(response => commit('setSeasonOptions', response.data))
    }
  }
})

export default store
export const useStore = () => store
