<template>
  <header id="page-header">
    <!-- Header Content -->
    <div class="content-header">
      <!-- Left Section -->
      <div class="d-flex align-items-center">
        <!-- Toggle Sidebar -->
        <!-- Layout API, functionality initialized in Template._uiApiLayout()-->
        <button type="button"
                @click="toggleSidebar()"
                class="btn btn-sm btn-dual mr-2 d-lg-none">
          <i class="fa fa-fw fa-bars"></i>
        </button>
        <!-- END Toggle Sidebar -->

        <!-- Toggle Mini Sidebar -->
        <!-- Layout API, functionality initialized in Template._uiApiLayout()-->
        <button type="button"
                class="btn btn-sm btn-dual d-none mr-1 d-lg-inline-block"
                @click="toggleMiniSidebar()">
          <i class="fa fa-fw fa-ellipsis-v"></i>
        </button>
        <button v-if="finishedGames.length" @click="$modal.show('finished-games')" type="button" class="btn btn-sm btn-danger text-white ml-1">
          <i class="fa fa-fw fa-bell"></i>
        </button>
      </div>
      <!-- END Left Section -->

      <!-- Right Section -->
      <div class="d-flex align-items-center">
        <div class="dropdown d-inline-block ml-2">
          <button type="button"
                  class="btn btn-sm btn-dual"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false">
            <i style="font-size: 20px;" class="fa fa-language"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-right p-0 border-0 font-size-sm"
               aria-labelledby="page-header-notifications-dropdown">
            <div class="p-2 bg-primary text-center">
              <h5 class="dropdown-header text-uppercase text-white">{{ $tc('language.name', 2) }}</h5>
            </div>
            <ul class="nav-items mb-0">
              <li v-for="lng in availableLanguages" :class="({'bg-info-light': currentLanguage === lng.id})" :key="lng.id">
                <a class="text-dark media py-2" href="#" @click.prevent="setLanguage(lng.id)">
                  <div class="mr-2 ml-3">
                    <i style="font-size: 16px" :class="'flag-icon flag-icon-' + lng.img"></i>
                  </div>
                  <div class="media-body pr-2">
                    <div class="font-w600">{{ $t(lng.name) }}</div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <!-- END Language Dropdown -->
        <!-- User Dropdown -->
        <div class="dropdown d-inline-block ml-2">
          <button type="button"
                  class="btn btn-sm btn-dual"
                  id="page-header-user-dropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false">
            <img class="rounded"
                 src="@/assets/media/avatars/avatar10.jpg"
                 alt="Header Avatar"
                 style="width: 18px;">
            <span class="d-none d-sm-inline-block ml-1">{{ $store.state.user.name }}</span>
            <i class="fa fa-fw fa-angle-down d-none d-sm-inline-block"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-right p-0 border-0 font-size-sm"
               aria-labelledby="page-header-user-dropdown">
            <div class="p-3 text-center bg-primary">
              <img class="img-avatar img-avatar48 img-avatar-thumb"
                   src="@/assets/media/avatars/avatar10.jpg"
                   alt="">
            </div>
            <div class="p-2">
              <h5 class="dropdown-header text-uppercase">Actions</h5>
              <a class="dropdown-item d-flex align-items-center justify-content-between"
                 @click.prevent="logout()">
                <span>Log Out</span>
                <i class="si si-logout ml-1"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- END Right Section -->
    </div>
    <!-- Header Loader -->
    <!-- Please check out the Loaders page under Components category to see examples of showing/hiding it -->
    <div id="page-header-loader" class="overlay-header bg-white">
      <div class="content-header">
        <div class="w-100 text-center">
          <i class="fa fa-fw fa-circle-notch fa-spin"></i>
        </div>
      </div>
    </div>
    <!-- END Header Loader -->
  </header>
</template>

<script>
import UserService from '../../services/user.service'
import { useRouteQuery } from '@/use/useRouteQuery'

export default {
  setup () {
    const sidebar = useRouteQuery('activities')

    return {
      sidebar
    }
  },
  data () {
    return {
      search: '',
      searchItems: [],
      user: {}
    }
  },
  watch: {
    language: 'changeLanguage'
  },
  mounted () {
  },
  methods: {
    toggleMiniSidebar () {
      this.$store.dispatch('toggleMiniNav')
    },
    toggleSidebar () {
      this.$store.dispatch('toggleNav')
    },
    itemClass (item) {
      switch (item.type) {
        case 'Coach':
          return 'fa-user-tie'
        case 'Player':
          return 'fa-child'
        case 'Club':
          return 'fa-city'
        case 'League':
          return 'fa-clipboard-list'
        case 'Season':
          return 'fa-trophy'
        case 'Venue':
          return 'fa-map-marker-alt'
      }
      return 'fa-star text-success'
    },
    setLanguage (language) {
      this.$store.dispatch('setLanguage', language)
    },
    logout () {
      UserService.logout()
    }
  },
  computed: {
    availableLanguages () {
      return this.$store.state.availableLanguages
    },
    currentLanguage () {
      return this.$store.state.language
    },
    finishedGames () {
      return this.$store.state.finishedGames
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
