<template>
  <div class="content">
    <Mobile ref="mobile" v-if="isMobile"></Mobile>
    <Desktop ref="desktop" v-else></Desktop>
    <div class="sidebar" :class="{'show': isSidebarOpen}">
      <div class="sidebar-overlay" @click="isSidebarOpen = false" />
      <div class="sidebar-inner">
        <div class="row">
          <div class="col-lg-8 offset-lg-2 timeline-wrapper">
            <ul class="timeline timeline-centered">
              <!-- Twitter Event -->
              <li
                v-for="gameLog in model.logs" :key="gameLog.uuid"
                :class="{
                'timeline-event-alt': isLogSideAlt(gameLog),
                'reversed': reversedTeams
              }"
                class="timeline-event"
              >
                <div class="timeline-event-icon border border-info h-auto overflow-hidden">
                  <div class="bg-info">
                    {{ logScore(gameLog) }}
                  </div>
                  <div class="bg-light text-info">
                    {{ gameLog.time }}
                  </div>
                </div>
                <div class="timeline-event-block block js-appear-enabled animated fadeIn rounded"
                     data-toggle="appear">
                  <div class="block-header block-header-default px-1 py-1">
                    <div class="flex flex-grow-1">
                    <span class="text-white px-2 rounded ml-1" :style="{'background-color': gameLogColor(gameLog)}">
                    {{
                        $t('live.sports.' + config.sport + '.game_log_buttons.' + gameLog.relatedTo + '.' + gameLog.type)
                      }}</span>
                      <span class="m-0 w-100 ml-2" v-if="gameLog.team === model.homeTeam.uuid">{{
                          `${model.homeTeam.name}`
                        }}</span>
                      <span class="m-0 w-100 ml-2" v-else>{{ `${model.awayTeam.name}` }}</span>
                    </div>

                    <div class="block-options">
                      <!--<div class="timeline-event-time block-options-item  font-w600">-->
                      <!--10 min ago-->
                      <!--</div>-->
                      <div class="block-options-item  font-w600">
                        <button @click="deleteLog(gameLog.uuid)" type="button" class="btn btn-danger btn-sm">
                          <i class="fa fa-times"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="block-content p-1">
                    <div><span
                      v-if="gameLog.relatedTo === 'player'"><span><strong class="font-size-h5 mx-2">#{{ gameLog.number_of_jersey }}</strong></span></span>
                      <span v-if="gameLog.relatedTo === 'player'">{{gameLog.playerOn.name}}</span>

                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div
      class="timer-toggle"
    >
      <LeStopwatch
        ref="timer"
        v-if="countdownLoaded"
        :is-reverse="config.time_reverse"
        :starting-time-data="timerTime"
        :period-time="model.schedule.periodTime"
        :current-period="model.current_period"
      />
    </div>
    <modal @before-open="onEntityModalOpened()" @before-close="onEntityModalClosed()" name="entity-modal" height="auto">
      <div class="p-2">
          <h3 class="text-center">{{ addDebtEntity?.name }}</h3>
      </div>
      <ntm-block :collapsable="false" v-if="configAutoActions?.length && addDebtEntityType === 'player'" :title="$t('player.change_position')">
        <form @submit.prevent="addDebt()">
          <le-position-selection ref="positionSelection" :options="configAutoActions" :entity="addDebtEntity" :entity-type="addDebtEntityType" @selected="changeEntityPosition"></le-position-selection>
        </form>
      </ntm-block>
      <ntm-block :collapsable="Boolean(configAutoActions?.length)" :init-collapse="!configAutoActions?.length || addDebtEntityType === 'team'" :title="$t('debt.add')">
        <form @submit.prevent="addDebt()">
          <formly-form :form="addDebtForm" :model="addDebtModel" :fields="addDebtFields"></formly-form>
          <button class="btn btn-success" type="submit"><span v-if="!addDebtLoading">{{ $t('debt.add') }}</span> <i v-if="addDebtLoading" class="fa fa-cog fa-spin"/></button>
        </form>
      </ntm-block>
    </modal>
    <log-notifications
      class="notification-wrapper"
      group="logs"
      :max="4"
      position="bottom center">
        <template v-slot:body="props">
          <div class="notification-body d-flex justify-content-between align-items-center" style="background-color: #e6e6e6">
            <div class="d-flex align-items-center justify-content-between flex-grow-1">
              <div v-if="props.item.data.relatedTo === 'player'" class="d-flex align-items-center">
                <span class="mr-2">
                  <span style="font-size: 14px; color: #999999" class="mr-1">#</span><strong>{{props.item.data.number_of_jersey}}</strong>
                </span>
                <span style="font-size: 14px;" class="player-name">{{props.item.data.playerOn.name}}</span>
              </div>
              <div v-if="props.item.data.relatedTo === 'team'" class="d-flex align-items-center">
                <span style="font-size: 14px;" class="player-name" v-if="props.item.data.team === model.homeTeam.uuid">{{
                    `${model.homeTeam.name}`
                  }}
                </span>
                <span style="font-size: 14px;" class="player-name" v-if="props.item.data.team === model.awayTeam.uuid">{{
                    `${model.awayTeam.name}`
                  }}
                </span>
              </div>
              <span class="text-white badge badge-pill mr-1 log-type" :style="{'background-color': gameLogColor(props.item.data)}">
                {{$t('live.sports.' + config.sport + '.game_log_buttons.' + props.item.data.relatedTo + '.' + props.item.data.type)}}
              </span>
            </div>
            <button class="close" @click="deleteLogWithoutAsking(props)">
              <i class="fa fa-fw fa-trash text-danger"></i>
            </button>
          </div>

        </template>
    </log-notifications>
    <log-notifications
      class="notification-wrapper"
      group="deletedLogs"
      :max="2"
      position="top center">
      <template v-slot:body="props">
        <div class="notification-body deleted d-flex justify-content-between align-items-center flex-column" style="background-color: #e6e6e6">
          <div class="d-flex align-items-center justify-content-between w-100">
            <div v-if="props.item.data.relatedTo === 'player'" class="d-flex align-items-center">
                <span class="mr-2">
                  <span style="font-size: 14px; color: #999999" class="mr-1">#</span><strong>{{props.item.data.number_of_jersey}}</strong>
                </span>
              <span style="font-size: 14px;" class="player-name">{{props.item.data.playerOn.name}}</span>
            </div>
            <div v-if="props.item.data.relatedTo === 'team'" class="d-flex align-items-center">
                <span style="font-size: 14px;" class="player-name" v-if="props.item.data.team === model.homeTeam.uuid">{{
                    `${model.homeTeam.name}`
                  }}
                </span>
              <span style="font-size: 14px;" class="player-name" v-if="props.item.data.team === model.awayTeam.uuid">{{
                  `${model.awayTeam.name}`
                }}
                </span>
            </div>
            <span class="text-white badge badge-pill mr-1 log-type" :style="{'background-color': gameLogColor(props.item.data)}">
                {{$t('live.sports.' + config.sport + '.game_log_buttons.' + props.item.data.relatedTo + '.' + props.item.data.type)}}
              </span>
          </div>
        </div>

      </template>
    </log-notifications>
  </div>
</template>
<script>

import Desktop from '@/views/live/desktop/Desktop.vue'
import GameService from '@/services/game.service'
import router from '@/router'
import Mobile from '@/views/live/mobile/Mobile.vue'
import VueI18n from '@/i18n'
import { useLive } from '@/views/live/use/useLive'
import { addDebtFields } from '@/formDefinitions/addDebt'
import LeStopwatch from '@/components/Stopwatch.vue'
import LePositionSelection from '@/components/le/LePositionSelection.vue'

export default {
  components: {
    LePositionSelection,
    LeStopwatch,
    Mobile,
    Desktop
  },
  setup () {
    const live = useLive()

    return { ...live }
  },
  data () {
    return {
      defaultDebtModel: {},
      addDebtModel: {},
      addDebtLoading: false,
      addDebtForm: {},
      addDebtFields: addDebtFields,
      isMobile: false
    }
  },
  created () {
    this.checkIfMobile()
    window.addEventListener('resize', this.checkIfMobile)
  },
  mounted () {
    this.defaultDebtModel = {
      amount: null,
      type: 'PUNISHMENT'
    }
    this.addDebtModel = { ...this.defaultDebtModel }

    this.onInit()
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.checkIfMobile)
  },
  destroyed () {
    this.onDestroy()
  },
  computed: {
    model () {
      return this.$store.state.main.model
    },
    config () {
      return this.$store.state.config
    }
  },
  methods: {
    sidebarClickOutside () {
      this.isSidebarOpen = false
    },
    changeEntityPosition (action) {
      this.changePlayerPosition(action)
      setTimeout(() => {
        this.$refs.mobile?.rerender()
        this.$refs.desktop?.rerender()
      })
    },
    addDebt () {
      this.addDebtForm.$errors = {}
      this.addDebtLoading = true
      let request = null

      if (this.addDebtEntityType === 'player') {
        request = GameService.addPlayerDebt({ ...this.addDebtModel, uuid: this.addDebtEntity.uuid }).then(() => {
          this.addDebtModel = { ...this.defaultDebtModel }

          this.$modal.hide('entity-modal')
        })
      } else if (this.addDebtEntityType === 'team') {
        request = GameService.addTeamDebt({ ...this.addDebtModel, uuid: this.addDebtEntity.uuid }).then(() => {
          this.addDebtModel = { ...this.defaultDebtModel }

          this.$modal.hide('entity-modal')
        })
      }

      if (request) {
        request.then(() => {
          this.addDebtModel = { ...this.defaultDebtModel }

          this.$modal.hide('entity-modal')
        }).catch((err) => {
          this.addDebtForm.$errors = err.response.data.errors
        }).finally(() => {
          this.addDebtLoading = false
        })
      }
    },
    isLogSideAlt (gameLog) {
      return this.reversedTeams ? (gameLog.team !== this.model.awayTeam.uuid) : (gameLog.team === this.model.awayTeam.uuid)
    },
    logScore (gameLog) {
      if (this.reversedTeams) {
        return `${gameLog.away_team_score} : ${gameLog.home_team_score}`
      }

      return `${gameLog.home_team_score} : ${gameLog.away_team_score}`
    },
    deleteLog (uuid) {
      GameService.deleteLog(uuid)
    },
    deleteLogWithoutAsking (props) {
      GameService.deleteLogWithoutAsking(props.item.data.uuid)
      props.close()
    },
    translateLog (gameLog) {
      if (gameLog.against === null && gameLog.teammate === null) {
        return VueI18n.t('live.sports.' + this.config.sport + '.logs.' + gameLog.relatedTo + '.' + gameLog.type + '.alone', [gameLog.on])
      } else {
        if (gameLog.against !== null) {
          return VueI18n.t('live.sports.' + this.config.sport + '.logs.' + gameLog.relatedTo + '.' + gameLog.type + '.against', [gameLog.on, gameLog.against])
        } else if (gameLog.teammate !== null) {
          return VueI18n.t('live.sports.' + this.config.sport + '.logs.' + gameLog.relatedTo + '.' + gameLog.type + '.teammate', [gameLog.on, gameLog.teammate])
        }
      }
    },
    checkIfMobile () {
      this.isMobile = window.innerWidth <= 600
    }
  },
  beforeRouteEnter (to, from, next) {
    GameService.status().then((response) => {
      if (response.data === 'SCHEDULED') {
        router.push({ name: 'preGame' })
      } else if (response.data === 'FINISHED') {
        router.push({ name: 'archive' })
      } else if (response.data === 'LIVE') {
        GameService.live().then((response) => {
          next(vm => vm.$store.dispatch('main/setModel', response.data))
        })
      }
    })
  }
}
</script>
