<template>
  <div>
  </div>
</template>

<script>
import router from '@/router'

export default {
  async beforeMount () {
    if (this.$store.getters.isAdmin || this.$store.getters.isManager) {
      await router.push({
        name: 'games',
        params: { clientId: this.currentClient.uuid }
      })
    } else {
      await router.push({
        name: 'enterTheGame',
        params: { clientId: this.currentClient.uuid }
      })
    }
  },
  computed: {
    currentClient () {
      return this.$store.state.currentClient
    }
  }
}
</script>
