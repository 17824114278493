const state = {
  isMobile: false,
  loading: true,
  reversedTeams: false,
  invertedTable: false,
  autoUnselect: false,
  mobileSettings: false,
  actionsPosition: '2',
  model: {
    uuid: '',
    awayTeam: {
      name: '',
      players: []
    },
    homeTeam: {
      name: '',
      players: []
    },
    score: {
      home: 0,
      away: 0
    },
    scoreByPeriod: [
      {
        home: 1,
        away: 0
      },
      {
        home: 0,
        away: 1
      },
      {
        home: 0,
        away: 0
      }
    ],
    model: null
  }
}

const mutations = {
  reversedTeams (state) {
    state.reversedTeams = !state.reversedTeams
  },
  invertedTable (state) {
    state.invertedTable = !state.invertedTable
  },
  mobileSettings (state) {
    state.mobileSettings = !state.mobileSettings
  },
  autoUnselect (state, autoUnselect) {
    state.autoUnselect = autoUnselect
    localStorage.setItem('autoUnselect', state.autoUnselect)
  },
  actionsPosition (state, actionsPosition) {
    state.actionsPosition = actionsPosition
  },
  setModel (state, model) {
    state.model = model
  },
  setIsMobile (state, isMobile) {
    state.isMobile = isMobile
  }
}

const actions = {
  setModel ({ commit }, model) {
    commit('setModel', model)
  },
  setIsMobile ({ commit }, isMobile) {
    commit('setIsMobile', isMobile)
  }
}
export const main = {
  namespaced: true,
  state,
  actions,
  mutations
}
